import {
  type InstantMeiliSearchInstance,
  instantMeiliSearch,
} from '@meilisearch/instant-meilisearch'
import { useNuxtApp, useRuntimeConfig } from '#app'

interface NuxtAppWithMeiliSearch extends ReturnType<typeof useNuxtApp> {
  _meilisearchClient?: InstantMeiliSearchInstance
}

/**
 * Returns a singleton instance of the Meilisearch client.
 */
export function useMeilisearchClient() {
  const nuxtApp = useNuxtApp() as NuxtAppWithMeiliSearch

  const {
    meilisearchClient: { hostUrl, searchApiKey, clientOptions },
  } = useRuntimeConfig().public

  if (!nuxtApp._meilisearchClient) {
    try {
      nuxtApp._meilisearchClient = instantMeiliSearch(
        hostUrl,
        searchApiKey,
        clientOptions,
      ).searchClient
    }
    catch (error) {
      console.error('Failed to initialize Meilisearch client:', error)
    }
  }

  return nuxtApp._meilisearchClient
}
